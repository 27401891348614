import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const BulkOrder = ({ open, onClose, orderTitle, orderData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{orderTitle}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Order Details:</Typography>
        <Typography>{JSON.stringify(orderData, null, 2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const processBulkOrder = async (barcode, setOrderData) => {
  const orderNumber = barcode.replace(/^(PWB|BLK)/, '');
  // Fetch data or process it (mocked here for simplicity)
  setOrderData({ id: orderNumber, items: [{ product: 'Item 1', qty: 2 }] });
};

export default BulkOrder;