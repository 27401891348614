import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  Button,
  MenuItem,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Typography,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Add,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  ControlPoint as ControlPointIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import Person2Icon from '@mui/icons-material/Person2';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { ReactComponent as Logo } from '../assets/bulk-logo.svg';


const CreateBulkOrder = () => {
  const token = sessionStorage.getItem('pfresh_bulk_jwt_token');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [accordionManuallyOpened, setAccordionManuallyOpened] = useState(false);
  const [orderedItems, setOrderedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rightPanelLoading, setRightPanelLoading] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [collectionWeeks, setCollectionWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [pastCutoffModal, setPastCutoffModal] = useState({ open: false, date: '' });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [lastThreeOrders, setLastThreeOrders] = useState([]);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [isCustomerNotFoundModal, setIsCustomerNotFoundModal] = useState(false);
  const [outOfStockModal, setOutOfStockModal] = useState({ open: false, productName: '' });
  const products = JSON.parse(localStorage.getItem('bulk_products') || '{"data": []}').data || [];
  const stores = JSON.parse(localStorage.getItem('bulk_stores') || '{"data": []}').data || [];
  const [customerModalType, setCustomerModalType] = useState('create'); // 'create', 'update', 'select'
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [preferredStore, setPreferredStore] = useState('');


  const groupedProducts = (products || []).reduce((groups, product) => {
    if (product.categories && Array.isArray(product.categories)) {
      product.categories.forEach((category) => {
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(product);
      });
    }
    return groups;
  }, {});


  if (!groupedProducts || Object.keys(groupedProducts).length === 0) {
    console.warn('No products to display. Check the data structure or API response.');
  }

  if (!Array.isArray(products)) {
    console.error("Expected an array for 'bulk_products'. Check localStorage data structure.");
  }
  if (!Array.isArray(stores)) {
    console.error("Expected an array for 'bulk_stores'. Check API response or localStorage.");
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storedProducts = localStorage.getItem('bulk_products');
        const storedStores = localStorage.getItem('bulk_stores');

        if (!storedProducts || Date.now() - new Date(JSON.parse(storedProducts).timestamp) > 12 * 60 * 60 * 1000) {
          const productResponse = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/get-products', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const productsData = await productResponse.json();
          localStorage.setItem('bulk_products', JSON.stringify({ data: productsData, timestamp: Date.now() }));
        }

        if (!storedStores || Date.now() - JSON.parse(storedStores).timestamp > 12 * 60 * 60 * 1000) {
          const storeResponse = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/stores', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const storesData = await storeResponse.json();
          localStorage.setItem('bulk_stores', JSON.stringify({ data: storesData.data, timestamp: Date.now() }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(false);
    setPhoneNumber(customer.phone);
    setError('');
    setSnackbarOpen(false);
  };

  const CloseButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: 'red',
        color: 'white',
        '&:hover': { backgroundColor: 'darkred' },
      }}
    >
      Close
    </Button>
  );

  const handleAddProduct = () => {
    const newProduct = {
      image: 'Image',
      name: 'Product 1',
      qty: 5,
    };
    setOrderedItems([...orderedItems, newProduct]);
  };

  const handlePlaceOrderHover = (isHovering) => {
    if (isHovering && !isAccordionOpen) {
      setIsAccordionOpen(true);
    } else if (!isHovering && !accordionManuallyOpened) {
      setIsAccordionOpen(false);
    }
  };

  useEffect(() => {
    fetchCollectionWeeks();
  }, []);

  const fetchCollectionWeeks = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/available-sundays/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCollectionWeeks(data);
    } catch (error) {
      console.error('Error fetching collection weeks:', error);
    } finally {
      setLoading(false);
    }
  };

  const getTimeDifference = (date) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      const weeks = Math.floor((diffDays % 30) / 7);
      return `${months} months${weeks ? ` and ${weeks} weeks` : ''} ago`;
    }
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    return `${years} years${months ? ` and ${months} months` : ''} ago`;
  };

  const handlePhoneNumberChange = async (event) => {
    const value = event.target.value;
    setPhoneNumber(value);

    if (value.length < 10) {
      setSelectedCustomer(null);
      return;
    }

    if (value.length === 10) {
      setPhoneLoading(true);
      try {
        setError('');
        const response = await fetch(
          `https://order.petfresh.com.au/wp-json/wc/v3/get-customers?search=${value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        if (data.length === 0) {
          setCustomerModalType('create');
          setCustomerModalOpen(true);
        } else if (data.length === 1) {
          setCustomerModalType('select');
          setCustomers(data);
          setCustomerModalOpen(true);
        } else if (data.length > 1) {
          setCustomerModalType('select');
          setCustomers(data);
          setCustomerModalOpen(true);
        }
      } catch (error) {
        setError('Failed to fetch customers. Please try again later.');
        setSnackbarOpen(true);
        console.error('Error fetching customers:', error);
      } finally {
        setPhoneLoading(false);
      }
    }
  };

  const handleShowOrderHistory = () => {
    const orders = selectedCustomer.last_orders.slice(0, 3);
    setLastThreeOrders(orders);
    setHistoryModalOpen(true);
  };

  const handleWeekChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = collectionWeeks.find((week) => week.Date === selectedValue);
    setSelectedWeek(selectedValue);

    if (selectedOption?.['Past Cutoff']) {
      setPastCutoffModal({ open: true, date: selectedOption.Date });
    }
  };

  const closePastCutoffModal = () => {
    setPastCutoffModal({ open: false, date: '' });
    setSelectedWeek('');
  };



  const handleCreateCustomer = async () => {
    setOverlayLoading(true);
    const newCustomer = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      preferred_store: preferredStore,
    };
    try {
      const response = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCustomer),
      });
      const data = await response.json();

      if (response.status === 409) {
        setError(`A user already exists with the phone or email '${phoneNumber}'.`);
        setCustomerModalType('select');
        setCustomers([data.existingCustomer]); // Populate with existing customer
      } else {
        setCustomerModalOpen(false);
        handleSelectCustomer(data.data);
      }
    } catch (error) {
      console.error('Error creating customer:', error);
      setError(`Customer couldn't be created: ${error.message}`);
      setSnackbarOpen(true);
    } finally {
      setOverlayLoading(false);
    }
  };

  const handleAddToOrder = (product) => {
    console.log('Adding product:', product); // Debugging log
    if (product.stock_status === 'out_of_stock') {
      setOutOfStockModal({ open: true, productName: product.name });
      return;
    }
    setOrderedItems((prevItems) => [...prevItems, product]);
    console.log('Ordered Items Updated:', orderedItems); // Debugging log
  };

  const renderCustomerModalContent = () => {
    switch (customerModalType) {
      case 'create':
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Create New Customer</Typography>
              <CloseButton onClick={() => setCustomerModalOpen(false)} />
            </Box>
            <Box sx={{ mb: '1em' }}>
              <TextField label="First Name" fullWidth required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </Box>
            <Box sx={{ mb: '1em' }}>
              <TextField label="Last Name" fullWidth required value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Box>
            <Box sx={{ mb: '1em' }}>
              <TextField label="Email Address" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} />
            </Box>
            <Box sx={{ mb: '1em' }}>
              <TextField label="Mobile Phone Number" fullWidth required value={phoneNumber} disabled />
            </Box>
            <Box sx={{ mb: '1em' }}>
              <TextField
                select
                label="Preferred Store"
                fullWidth
                required
                value={preferredStore}
                onChange={(e) => setPreferredStore(e.target.value)}
              >
                <MenuItem value="">Please Select Store</MenuItem>
                {stores.map((store) => (
                  <MenuItem key={store.id} value={store.storename}>
                    {store.storename}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" sx={{ color: '#9ba03b', borderColor: '#9ba03b' }} onClick={() => setCustomerModalOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCreateCustomer}>
                Add Customer
              </Button>
            </Box>
          </Box>
        );
  
      case 'select':
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Select Existing Customer</Typography>
              <CloseButton onClick={() => setCustomerModalOpen(false)} />
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Last Order</TableCell>
                    <TableCell>Select</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.user_id}>
                      <TableCell>{`${customer.first_name} ${customer.last_name}`}</TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>{customer.phone}</TableCell>
                      <TableCell>{getTimeDifference(customer.last_orders[0]?.date || '')}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleSelectCustomer(customer)}>
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
  
      case 'update':
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Update Customer</Typography>
              <CloseButton onClick={() => setCustomerModalOpen(false)} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              Update functionality is under construction.
            </Typography>
          </Box>
        );
  
      default:
        return null;
    }
  };

  return (
    <Box className="order-area" sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
      <style>
        {`
          .order-area {
            margin-left: 0px;
            margin-right: 0px;
          }
          .Quadrant {
            background-color: white;
          }
          textarea[resize] {
            resize: none;
          }
            .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            z-index: 1000;
          }
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            z-index: 1000;
          }
        `}
      </style>

      {/* Top Section */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Left Column */}
        <Box
          sx={{
            width: '60%',
            height: '100%',
            overflow: 'hidden',
            borderRight: '1px solid #ccc',
            padding: '1em 1em 0em 1em',
            position: 'relative',
            overflowY: 'auto',
          }}
          className="Quadrant"
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedProducts &&
                  Object.entries(groupedProducts).map(([category, items]) => (
                    <React.Fragment key={category}>
                      <TableRow>
                        <TableCell colSpan={5} style={{ backgroundColor: '#f6f6f6', fontWeight: 'bold' }}>
                          {category}
                        </TableCell>
                      </TableRow>
                      {items.map((product) => (
                        <TableRow
                          key={product.name}
                          style={{
                            backgroundColor: product.stock_status === 'out_of_stock' ? '#ffcccc' : 'transparent',
                          }}
                        >
                          <TableCell>
                            {product.thumbnail ? (
                              <img src={product.thumbnail} alt="Thumbnail" style={{ width: '50px' }} />
                            ) : (
                              'No Image'
                            )}
                          </TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.price}</TableCell>
                          <TableCell>
                            <TextField type="number" size="small" defaultValue={1} />
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleAddToOrder(product)}
                              variant="contained"
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Accordion for Ordered Items */}
          {orderedItems.length > 0 && (
            <Accordion
              expanded={isAccordionOpen}
              onChange={() => {
                setIsAccordionOpen(!isAccordionOpen);
                setAccordionManuallyOpened(!isAccordionOpen);
              }}
              sx={{
                position: 'sticky',
                zIndex: 2,
                backgroundColor: '#f6f6f6',
              }}
            >
              <AccordionSummary
                style={{ color: 'white', backgroundColor: '#9ba03b' }}
                expandIcon={<ExpandMore style={{ color: 'white' }} />}
              >
                Ordered Items
              </AccordionSummary>
              <AccordionDetails
                style={{
                  backgroundColor: '#f6f6f6',
                  maxHeight: '60vh',
                  overflowY: 'auto',
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item Image</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderedItems.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.image}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.qty} kg</TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              variant="outlined"
                              style={{ border: '1px solid red', color: 'red' }}
                              onClick={() =>
                                setOrderedItems(orderedItems.filter((_, i) => i !== index))
                              }
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>

        {/* Full-Screen Spinner */}
        {loading && (
          <Box className="overlay">
            <CircularProgress className="loading-spinner" />
          </Box>
        )}

        {/* Overlay Spinner for Right Panel */}
        {rightPanelLoading && (
          <Box className="overlay">
            <CircularProgress className="loading-spinner" />
          </Box>
        )}


      <Modal open={customerModalOpen} onClose={() => setCustomerModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: '80%' }}>
          {renderCustomerModalContent()}
        </Box>
      </Modal>;

        <Modal open={outOfStockModal.open} onClose={() => setOutOfStockModal({ open: false, productName: '' })}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '50%',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: '#9ba03b' }}>
              Out of Stock
            </Typography>
            <Typography>{`The product ${outOfStockModal.productName} is currently out of stock.`}</Typography>
            <Button
              variant="contained"
              onClick={() => setOutOfStockModal({ open: false, productName: '' })}
              sx={{ mt: 2, backgroundColor: '#9ba03b', color: 'white' }}
            >
              Close
            </Button>
            <Tooltip title="Please make sure you speak to the production team by dialing 111 before creating an order for an unavailable product">
              <Button
                variant="outlined"
                sx={{ mt: 2, borderColor: '#9ba03b', color: '#9ba03b' }}
                onClick={() => {
                  setOrderedItems([...orderedItems, { name: outOfStockModal.productName, qty: 1 }]);
                  setOutOfStockModal({ open: false, productName: '' });
                }}
              >
                Add Anyway
              </Button>
            </Tooltip>
          </Box>
        </Modal>

        <Modal open={pastCutoffModal.open} onClose={closePastCutoffModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '50%',
            }}
          >
            <Typography variant="h6">
              You have selected a date which is PAST the week’s cutoff.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Are you sure you wish to place an order for the selected week of{' '}
              <b>{pastCutoffModal.date}</b>? <br />
              <b>You must check with the production team by dialing extension 111 before continuing.</b>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button variant="contained" onClick={closePastCutoffModal}>
                Cancel and Re-Select
              </Button>
              <Button variant="contained" color="primary" onClick={() => setPastCutoffModal({ open: false, date: '' })}>
                Proceed with Selected Date
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={historyModalOpen} onClose={() => setHistoryModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '80%',
            }}
          >
            <Typography variant="h6">Order History</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Items</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lastThreeOrders.map((order) => (
                    <TableRow key={order.order_id}>
                      <TableCell>{order.order_id}</TableCell>
                      <TableCell>{new Date(order.date).toLocaleDateString()}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>
                        {order.items.map((item) => (
                          <div key={item.name}>{`${item.name} (x${item.qty})`}</div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>

        <Modal open={customerModalOpen} onClose={() => setCustomerModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '80%',
            }}
          >
            {customerModalType === 'create' && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">Create New Customer</Typography>
                  <Button onClick={() => setCustomerModalOpen(false)}>Close</Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <TextField
                    label="First Name"
                    fullWidth
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <TextField
                    label="Last Name"
                    fullWidth
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Box>
                <TextField
                  label="Email Address"
                  fullWidth
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Mobile Phone Number"
                  fullWidth
                  required
                  value={phoneNumber}
                  sx={{ mt: 2 }}
                  disabled
                />
                <TextField
                  select
                  label="Preferred Store"
                  fullWidth
                  required
                  value={preferredStore}
                  onChange={(e) => setPreferredStore(e.target.value)}
                >
                  {Array.isArray(stores) &&
                    stores.map((store) => (
                      <MenuItem key={store.id} value={store.storename}>
                        {store.storename}
                      </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button
                    variant="outlined"
                    onClick={() => setCustomerModalOpen(false)}
                    sx={{ color: '#9ba03b', borderColor: '#9ba03b' }}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleCreateCustomer}>
                    Add Customer
                  </Button>
                </Box>
              </Box>
            )}

            {customerModalType === 'update' && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">Update Customer</Typography>
                  <Button onClick={() => setCustomerModalOpen(false)}>Close</Button>
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Update functionality is under construction.
                </Typography>
              </Box>
            )}

            {customerModalType === 'select' && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">Select Existing Customer</Typography>
                  <Button onClick={() => setCustomerModalOpen(false)}>Close</Button>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Last Order</TableCell>
                        <TableCell>Select</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customers.map((customer) => {
                        const lastOrderDate = customer.last_orders[0]?.date
                          ? new Date(customer.last_orders[0].date)
                          : null;
                        const lastOrderAgo = customer.last_orders[0]?.date
                          ? getTimeDifference(customer.last_orders[0].date)
                          : 'No orders';

                        return (
                          <TableRow key={customer.user_id}>
                            <TableCell>{`${customer.first_name} ${customer.last_name}`}</TableCell>
                            <TableCell>{customer.email}</TableCell>
                            <TableCell>{customer.phone}</TableCell>
                            <TableCell>{lastOrderAgo}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                onClick={() => handleSelectCustomer(customer)}
                              >
                                Select
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Modal>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>

        {/* Right Column */}
        <Box
          sx={{
            width: '40%',
            height: '100%',
            overflow: 'auto',
            padding: 2,
            overflowY: 'auto',
          }}
          className="Quadrant"
        >
          <TextField
            label="Phone Number"
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            InputProps={{
              startAdornment: phoneLoading ? <CircularProgress style={{ marginRight: "1em" }} size={20} /> : <SearchIcon style={{ marginRight: "1em" }} />,
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>Customer Details</h3>
            {selectedCustomer && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCustomerModalOpen(true)}
              >
                Update Customer
              </Button>
            )}
          </Box>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}` : ''}
            disabled
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email Address"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.email : ''}
            disabled
            sx={{ mb: 2 }}
          />
          <TextField
            label="Preferred Store"
            fullWidth
            variant="outlined"
            value={selectedCustomer ? selectedCustomer.preferred_store : ''}
            disabled
            sx={{ mb: 2 }}
          />
          <TextField
            select
            label="Collection Week"
            fullWidth
            variant="outlined"
            value={selectedWeek}
            onChange={handleWeekChange}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: <DateRangeIcon style={{ marginRight: "1em" }} />,
            }}
          >
            <MenuItem value="">Please Select</MenuItem>
            {collectionWeeks.map((week) => (
              <MenuItem
                key={week.Date}
                value={week.Date}
                style={{
                  backgroundColor: week['Past Cutoff'] || week.Closure ? '#df1b1b' : '#ACF3AE',
                  color: week['Past Cutoff'] || week.Closure ? 'white' : 'black',
                }}
                sx={{
                  '&:hover': {
                    filter: 'brightness(90%)',
                  },
                  '&.Mui-selected': {
                    filter: 'brightness(80%)',
                  },
                }}
              >
                {week['Display Text']}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Collection Store"
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          >
            {stores.map((store) => (
              <MenuItem key={store.id} value={store.storename}>
                {store.storename}
              </MenuItem>
            ))}
          </TextField>
          <TextareaAutosize
            minRows={5}
            placeholder="Notes"
            style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: 4, resize: 'none' }}
          />
        </Box>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          position: 'absolute', // Makes it float relative to the parent
          bottom: 0, // Sticks the footer to the bottom
          left: 0, // Keeps the left margin offset
          right: 0, // Keeps the right margin offset
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'black',
          padding: 2,
          height: '10%',
          boxSizing: 'border-box', // Ensures padding is included in the height
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {selectedCustomer?.last_orders?.length > 0 && (
            <Button
              sx={{ backgroundColor: '#9ba03b', color: 'white' }}
              startIcon={<HistoryIcon />}
              onClick={() => handleShowOrderHistory()}
            >
              Order History
            </Button>
          )}
          <Button
            sx={{ backgroundColor: '#9ba03b', color: 'white' }}
            startIcon={<EventRepeatIcon />}
          >
            Scheduled Orders
          </Button>
        </Box>
        <Button
          sx={{ backgroundColor: '#9ba03b', color: 'white' }}
          startIcon={<SaveIcon />}
          onMouseEnter={() => handlePlaceOrderHover(true)}
          onMouseLeave={() => handlePlaceOrderHover(false)}
        >
          PLACE ORDER
        </Button>
      </Box>
    </Box>
  );
};

export default CreateBulkOrder;