import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const WholesaleOrder = ({ open, onClose, orderTitle, orderData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{orderTitle}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Wholesale Order Details:</Typography>
        <Typography>{JSON.stringify(orderData, null, 2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const processWholesaleOrder = async (barcode, setOrderData) => {
  // Mocked processing logic
  setOrderData({ id: barcode.replace(/^WHL/, ''), items: [{ product: 'Bulk Item', qty: 50 }] });
};

export default WholesaleOrder;