import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    CircularProgress,
    Box,
    Typography,
    InputBase,
    Snackbar,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Container = styled(Box)({
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
});

const SearchBar = styled(InputBase)({
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "5px 10px",
    width: "100%",
    marginBottom: "20px",
});

const CenteredSpinner = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
});

const StickyHeader = styled(Box)({
    position: "sticky",
    top: "6em",
    zIndex: 1000,
    backgroundColor: "#ffffff",
    paddingBottom: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
});

const RowContainer = styled(Box)({
    position: "relative",
    display: "flex",
    alignItems: "center",
});

const SpinnerOverlay = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
});

const FiltersRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
});

const StockLevelManager = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [updateInProgress, setUpdateInProgress] = useState({});
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [filters, setFilters] = useState({
        outOfStock: false,
        inStock: false,
        managed: false,
        unmanaged: false,
    });
    const [unsavedChanges, setUnsavedChanges] = useState([]);
    const [stockLevels, setStockLevels] = useState({});

    const petFreshToken = sessionStorage.getItem("pfresh_main_jwt_token");
    const stockLevelToken = sessionStorage.getItem("wms_access_token");

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get("https://petfresh.com.au/wp-json/wc/v3/stock-levels", {
                    headers: { Authorization: `Bearer ${petFreshToken}` },
                });
                setProducts(response.data);
                setFilteredProducts(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [petFreshToken]);

    useEffect(() => {
        if (products.length === 0) return;

        const fetchStockLevels = async () => {
            try {
                const skus = products.map((product) => product.SKU);
                const response = await axios.post(
                    "https://wms-api.neicha.com.au/get-all-stock-levels",
                    { skus },
                    { headers: { Authorization: `Bearer ${stockLevelToken}` } }
                );
                const levels = response.data.reduce((acc, stock) => {
                    acc[stock.sku] = stock;
                    return acc;
                }, {});
                setStockLevels(levels);
            } catch (error) {
                console.error("Error fetching stock levels:", error);
            }
        };

        fetchStockLevels();
    }, [products, stockLevelToken]);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        filterProducts({ searchQuery: query, ...filters });
    };

    const handleSaveChanges = async () => {
        try {
            await axios.post(
                "https://petfresh.com.au/wp-json/wc/v3/update-stock-levels",
                { updates: unsavedChanges },
                { headers: { Authorization: `Bearer ${petFreshToken}` } }
            );
            setSnackbarMessage("Stock updated successfully!");
            setUnsavedChanges([]);
        } catch (error) {
            console.error("Error saving changes:", error);
            setSnackbarMessage("Failed to update stock.");
        }
    };

    const filterProducts = (updatedFilters) => {
        setFilters(updatedFilters);
        let filtered = products;

        if (updatedFilters.outOfStock) {
            filtered = filtered.filter((product) => product["CURRENT STOCK STATUS"] === "Out of Stock");
        }
        if (updatedFilters.inStock) {
            filtered = filtered.filter((product) => product["CURRENT STOCK STATUS"] === "In Stock");
        }
        if (updatedFilters.managed) {
            filtered = filtered.filter((product) => product["QTY MANAGED"] === "Yes");
        }
        if (updatedFilters.unmanaged) {
            filtered = filtered.filter((product) => product["QTY MANAGED"] !== "Yes");
        }
        if (searchQuery) {
            filtered = filtered.filter(
                (product) =>
                    product.Product.toLowerCase().includes(searchQuery) ||
                    (product.SKU && product.SKU.toLowerCase().includes(searchQuery))
            );
        }

        setFilteredProducts(filtered);
    };

    if (loading)
        return (
            <CenteredSpinner>
                <CircularProgress />
            </CenteredSpinner>
        );

    const groupedProducts = filteredProducts.reduce((acc, product) => {
        const category = product.CATEGORY || "Uncategorized";
        if (!acc[category]) acc[category] = [];
        acc[category].push(product);
        return acc;
    }, {});

    return (
        <Container>
            <StickyHeader>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" gutterBottom>
                        Stock Level Manager
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleSaveChanges} disabled={unsavedChanges.length === 0}>
                        Save
                    </Button>
                </Box>
                <SearchBar
                    placeholder="Search by product name or SKU..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <FiltersRow>
                    {Object.keys(filters).map((filter) => (
                        <FormControlLabel
                            key={filter}
                            control={
                                <Checkbox
                                    checked={filters[filter]}
                                    onChange={(e) => filterProducts({ ...filters, [filter]: e.target.checked })}
                                />
                            }
                            label={filter
                                .replace(/([A-Z])/g, " $1")
                                .toLowerCase()
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                        />
                    ))}
                </FiltersRow>
            </StickyHeader>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Current Status</TableCell>
                        <TableCell>Stock Managed</TableCell>
                        <TableCell>Retail Stock</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(groupedProducts).map((category) => (
                        <React.Fragment key={category}>
                            <TableRow>
                                <TableCell colSpan={6} style={{ fontWeight: "bold" }}>
                                    {category}
                                </TableCell>
                            </TableRow>
                            {groupedProducts[category].map((product) => (
                                <TableRow key={product.SKU}>
                                    <TableCell>
                                        <Tooltip title={product["CURRENT STOCK STATUS"]}>
                                            <Box
                                                style={{
                                                    backgroundColor:
                                                        product["CURRENT STOCK STATUS"] === "In Stock"
                                                            ? "green"
                                                            : "red",
                                                    borderRadius: "50%",
                                                    width: "12px",
                                                    height: "12px",
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{product.Product}</TableCell>
                                    <TableCell>{product.SKU}</TableCell>
                                    <TableCell>{product["CURRENT STOCK STATUS"]}</TableCell>
                                    <TableCell>
                                        <RadioGroup
                                            value={product["QTY MANAGED"] === "Yes" ? "managed" : "unmanaged"}
                                            onChange={(e) => {
                                                const newStatus = e.target.value;
                                                setUnsavedChanges((prev) => [
                                                    ...prev.filter((change) => change.id !== product.id),
                                                    {
                                                        id: product.id,
                                                        manage_stock: newStatus === "managed",
                                                        stock_level: newStatus === "managed" ? product["STOCK LEVEL"] : undefined,
                                                    },
                                                ]);
                                            }}
                                        >
                                            <FormControlLabel
                                                value="managed"
                                                control={<Radio />}
                                                label="Managed"
                                            />
                                            <FormControlLabel
                                                value="unmanaged"
                                                control={<Radio />}
                                                label="Unmanaged"
                                            />
                                        </RadioGroup>
                                    </TableCell>
                                    <TableCell>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Retail Store Stock</AccordionSummary>
                                            <AccordionDetails>
                                                {stockLevels[product.SKU]?.locations?.map((location, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{location.locationName}</TableCell>
                                                        <TableCell>{location.stockLevel}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Warehouse Stock</TableCell>
                                                    <TableCell>{stockLevels[product.SKU]?.warehouseStock || 0}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Held Stock</TableCell>
                                                    <TableCell>{stockLevels[product.SKU]?.onOrder || 0}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Overall Stock</TableCell>
                                                    <TableCell>{stockLevels[product.SKU]?.currentStockLevel || 0}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={3000}
                onClose={() => setSnackbarMessage("")}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default StockLevelManager;