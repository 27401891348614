import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, Pagination, CircularProgress, Chip, Checkbox, FormControlLabel } from '@mui/material';
import axios from 'axios';

const StockRequests = () => {
  const navigate = useNavigate();
  const [stockRequests, setStockRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const fetchStockRequests = async (page, includeDeleted) => {
    setLoading(true);
    const accessToken = sessionStorage.getItem('wms_access_token');
    try {
      const response = await axios.get(`https://wms-api.neicha.com.au/stock-requests?page=${page}&pageSize=10&includeDeleted=${includeDeleted}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setStockRequests(response.data.stockRequests);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stock requests:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStockRequests(currentPage, includeDeleted);
  }, [currentPage, includeDeleted]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchStockRequests(value, includeDeleted);
  };

  const handleIncludeDeletedChange = (event) => {
    setIncludeDeleted(event.target.checked);
  };

  const handleRefresh = () => {
    fetchStockRequests(currentPage, includeDeleted);
  };

  const priorityText = (priority) => {
    switch (priority) {
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return 'Unknown';
    }
  };

  const statusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#fff3cd'; // Slight yellow
      case 'Deleted':
        return '#f8d7da'; // Slight red
      case 'Pending Transfer':
        return '#cce5ff'; // Slight blue
      case 'Fulfilled':
        return '#d4edda'; // Slight green
      default:
        return ''; // Default chip background
    }
  };

  const rowBackgroundColor = (priority, status) => {
    if (priority === 2 && status !== 'Fulfilled') {
      return 'rgb(255 255 197 / 35%)';
    }
    if (priority === 3 && status !== 'Fulfilled') {
      return 'rgb(255 0 0 / 65%)';
    }
    return 'transparent';
  };

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
        <Typography variant="h4">Stock Requests</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/create-stock-request')}>
          Create a New Stock Request
        </Button>
      </Grid>
      <Grid container alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item style={{ flexGrow: 1 }}>
          <FormControlLabel
            control={<Checkbox checked={includeDeleted} onChange={handleIncludeDeletedChange} />}
            label="Show Deleted Requests"
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleRefresh}>
            Refresh
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
          <CircularProgress />
        </Grid>
      ) : stockRequests.length === 0 ? (
        <Typography variant="h6">No Stock Requests</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>REQ #</TableCell>
                  <TableCell>Store Name</TableCell>
                  <TableCell>Date Requested</TableCell>
                  <TableCell>Placed By</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockRequests.map((request) => (
                  <TableRow 
                    key={request.id} 
                    style={{ background: rowBackgroundColor(request.priority, request.status) }}
                  >
                    <TableCell>{request.id}</TableCell>
                    <TableCell>{request.storename}</TableCell>
                    <TableCell>{new Date(request.dateplaced).toLocaleString()}</TableCell>
                    <TableCell>{request.createdBy}</TableCell>
                    <TableCell>
                      <Chip label={request.status} style={{ backgroundColor: statusColor(request.status) }} />
                    </TableCell>
                    <TableCell>{priorityText(request.priority)}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="secondary" onClick={() => navigate(`/view-request/${request.id}`)}>
                        View Request
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
          />
        </>
      )}
    </div>
  );
};

export default StockRequests;