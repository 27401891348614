import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const WebOrder = ({ open, onClose, orderTitle, orderData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{orderTitle}</DialogTitle>
      <DialogContent>
        <Typography variant="h6">Web Order Data:</Typography>
        <Typography>{JSON.stringify(orderData, null, 2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const processWebOrder = async (barcode, setOrderData) => {
  setOrderData({ id: barcode, items: [] }); // Mocked data
};

export default WebOrder;
