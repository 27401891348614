import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';
import { CssBaseline, Drawer, List, ListItem, ListItemText, Container, CircularProgress, useMediaQuery, Toolbar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Collapse } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import ClickCollectOrders from './components/ClickCollectOrders';
import BulkOrders from './components/BulkOrders';
import StockRequests from './components/StockRequests';
import ConfigureStore from './components/ConfigureStore';
import Settings from './components/Settings';
import ViewRequest from './components/ViewRequest';
import CreateStockRequest from './components/CreateStockRequest';
import StoreSelection from './components/StoreSelection';
import Autoship from './components/AutoshipSchedules';
import BulkSchedules from './components/BulkSchedules';
import BulkScheduleDetails from './components/BulkScheduleDetails';
import BarcodeListen from './components/BarcodeListen';
import MobileHome from './components/Mobile/MobileHome';
import StockTake from './components/Mobile/StockTake';
import SearchInventory from './components/Mobile/SearchInventory';
import BatchReceiveBulkOrders from './components/Mobile/BatchReceiveBulkOrders';
import SmsCustomer from './components/Mobile/SmsCustomer';
import Production from './components/Production';
import ViewClickCollectOrder from './components/ViewClickCollectOrder';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Logout from './components/Logout';
import LabelTemplateBuilder from './components/LabelTemplate';
import MobileSettings from './components/Mobile/MobileSettings';
import StockLevelManager from './components/StockLevelManager';
import PickAndPack from './components/Mobile/PickAndPack';
import OrderReport from './components/OrderReport';
import CreateBulkOrder from './components/CreateBulkOrder';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const drawerWidth = '15vw';
const contentWidth = `85vw`

const theme = createTheme({
  palette: {
    primary: {
      main: '#9ba03b',
    },
    secondary: {
      main: '#000000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
});

const NavItem = ({ to, text, style, storeId, modalHandler }) => {
  const location = useLocation();
  const isActive = location.pathname === (storeId ? `${to}/${storeId}` : to);

  const handleClick = () => {
    if (modalHandler) {
      modalHandler(); // Open the modal instead of navigating
    } else {
      // Normal navigation behavior
      const linkTo = storeId ? `${to}/${storeId}` : to;
      window.location.href = linkTo; // Mimic navigation
    }
  };

  return (
    <ListItem
      button
      onClick={handleClick}
      style={{ ...style, backgroundColor: isActive ? '#f0f0f0' : 'inherit' }}
    >
      <ListItemText primary={text} />
    </ListItem>
  );
};

const App = () => {
  const { isLoading, isAuthenticated, user, getIdTokenClaims, loginWithRedirect, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [bulkOrderModalOpen, setBulkOrderModalOpen] = useState(false);

  const handleOpenBulkOrderModal = () => {
    setBulkOrderModalOpen(true);
  };

  const handleCloseBulkOrderModal = () => {
    setBulkOrderModalOpen(false);
  };

  const [selectedStore, setSelectedStore] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('selectedStore'));
    } catch (error) {
      return '';
    }
  });
  const isMobile = useMediaQuery('(max-width:768px)');
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installDialogOpen, setInstallDialogOpen] = useState(false);

  const [bulkOrdersOpen, setBulkOrdersOpen] = useState(false);

  const handleBulkOrdersClick = () => {
    setBulkOrdersOpen(!bulkOrdersOpen);
  };

  const clearStorageAndLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const saveUserData = async () => {
    if (user) {
      try {
        const claims = await getIdTokenClaims();
        const appMetadata = claims['https://wms.neicha.com.au/app_metadata'];

        if (appMetadata) {
          const { pfresh_main_jwt_token, pfresh_bulk_jwt_token, wms_access_token, wms_refresh_token, qpilot_token } = appMetadata;

          sessionStorage.setItem('pfresh_main_jwt_token', pfresh_main_jwt_token);
          sessionStorage.setItem('pfresh_bulk_jwt_token', pfresh_bulk_jwt_token);
          sessionStorage.setItem('wms_access_token', wms_access_token);
          sessionStorage.setItem('wms_refresh_token', wms_refresh_token);
          sessionStorage.setItem('qpilot_token', qpilot_token);

          setTimeout(() => {
            clearStorageAndLogout();
          }, 43200000);

          await fetchStores();
        } else {
          clearStorageAndLogout();
        }
      } catch (error) {
        clearStorageAndLogout();
      }
    }
  };

  const refreshWmsToken = async () => {
    const wms_refresh_token = sessionStorage.getItem('wms_refresh_token');
    if (!wms_refresh_token) {
      clearStorageAndLogout();
      return;
    }

    try {
      const response = await axios.post('https://wms-api.neicha.com.au/refresh-token', { refreshToken: wms_refresh_token });
      const { accessToken } = response.data;
      sessionStorage.setItem('wms_access_token', accessToken);

      setTimeout(refreshWmsToken, 3600000 * 8);
    } catch (error) {
      clearStorageAndLogout();
    }
  };

  const fetchStores = async () => {
    try {
      const access_token = sessionStorage.getItem('wms_access_token');
      if (!access_token) {
        throw new Error('Access token not found in session storage');
      }

      const response = await axios.get('https://wms-api.neicha.com.au/stores', {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      setStores(response.data);
      setLoading(false);
    } catch (error) {
      clearStorageAndLogout();
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setInstallDialogOpen(false); // Close the dialog after the user's choice
      });
    }
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated) {
      saveUserData().then(() => refreshWmsToken());
    }
  }, [isAuthenticated]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearStorageAndLogout();
  };

  const handleStoreSelect = (store) => {
    setSelectedStore(store);
    localStorage.setItem('selectedStore', JSON.stringify(store));
  };

  if (isLoading || loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: '#9ba03b' }} />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Header
          anchorEl={anchorEl}
          handleMenu={handleMenu}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
        {isMobile ? (
          selectedStore ? (
            <div style={{ backgroundColor: '#f1f1f1', minHeight: '102vh' }}>
              <Routes>
                <Route path="/mobile/home" element={<MobileHome />} />
                <Route path="/mobile/stock-take" element={<StockTake />} />
                <Route path="/mobile/search-inventory" element={<SearchInventory />} />
                <Route path="/mobile/batch-receive-bulk-orders" element={<BatchReceiveBulkOrders />} />
                <Route path="/mobile/sms-customer" element={<SmsCustomer />} />
                <Route path="/mobile/settings" element={<MobileSettings stores={stores} />} />
                <Route path="/mobile/pick-pack" element={<PickAndPack />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="*" element={<Navigate to="/mobile/home" replace />} />
              </Routes>
            </div>
          ) : (
            <StoreSelection stores={stores} onStoreSelect={handleStoreSelect} />
          )
        ) : selectedStore ? (
          <>
            <BarcodeListen />
            <Drawer
              variant="permanent"
              style={{
                width: drawerWidth,
                flexShrink: 0,
              }}
              PaperProps={{
                style: {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
            >
              <Toolbar />
              <div>
              <List>
                <NavItem style={{ cursor: 'pointer' }} to="/dashboard" text="Dashboard" />
                <NavItem style={{ cursor: 'pointer' }} to="/click-collect-orders" text="Click & Collect Orders" />
                {/* Accordion for Bulk Orders */}
                <ListItem button onClick={handleBulkOrdersClick}>
                  <ListItemText primary="Bulk Orders" />
                  {bulkOrdersOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={bulkOrdersOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                  <NavItem
                    style={{ paddingLeft: '2em', cursor: 'pointer' }}
                    text="Create Bulk Order"
                    modalHandler={handleOpenBulkOrderModal}
                  />
                    <NavItem
                      style={{ paddingLeft: '2em', cursor: 'pointer' }}
                      to="/bulk-orders"
                      text="Search Bulk Orders"
                    />
                    <NavItem
                      style={{ paddingLeft: '2em', cursor: 'pointer' }}
                      to="/bulk-schedules"
                      text="Bulk Schedules"
                    />

                    <NavItem
                      style={{ paddingLeft: '2em', cursor: 'pointer' }}
                      to="/order-report"
                      text="Order Report"
                    />
                  </List>
                </Collapse>
                <NavItem style={{ cursor: 'pointer' }} to="/stock-requests" text="Stock Requests" />
                <NavItem style={{ cursor: 'pointer' }} to="/autoship-schedules" text="Autoship Schedules" />
                <NavItem style={{ cursor: 'pointer' }} to="/production" text="Production Reporting" />
                <NavItem style={{ cursor: 'pointer' }} to="/stock-availability" text="Stock Availability" />
                <NavItem
                  style={{ cursor: 'pointer' }}
                  to="/configure-store"
                  text="Configure Store"
                  storeId={selectedStore?.id}
                />
                <NavItem style={{ cursor: 'pointer' }} to="/settings" text="Settings" />
              </List>
              </div>
            </Drawer>
            <Container style={{ marginLeft: drawerWidth, width: contentWidth, backgroundColor: "#fafafa", paddingTop: '6em' }}>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-bulk-order" element={<CreateBulkOrder />} />
                <Route path="/order-report" element={<OrderReport />} />
                <Route path="/click-collect-orders" element={<ClickCollectOrders stores={stores} />} />
                <Route path="/bulk-orders" element={<BulkOrders />} />
                <Route path="/stock-requests" element={<StockRequests />} />
                <Route
                  path="/configure-store/:id"
                  element={<ConfigureStore stores={stores} />}
                />
                <Route path="/settings" element={<Settings stores={stores} />} />
                <Route path="/autoship-schedules" element={<Autoship />} />
                <Route path="/bulk-schedules" element={<BulkSchedules />} />
                <Route path="/bulk-schedules/:id" element={<BulkScheduleDetails />} />
                <Route path="/view-request/:id" element={<ViewRequest />} />
                <Route path="/view-click-collect-order/:id" element={<ViewClickCollectOrder />} />
                <Route path="/create-stock-request" element={<CreateStockRequest />} />
                <Route path="/production" element={<Production />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/label-template" element={<LabelTemplateBuilder />} />
                <Route path="/stock-availability" element={<StockLevelManager />} />
                
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Container>
            <Dialog
              fullScreen
              open={bulkOrderModalOpen}
              onClose={handleCloseBulkOrderModal}
              aria-labelledby="bulk-order-dialog-title"
            >
              <DialogTitle style={{backgroundColor: "#9ba03b", color: "white"}} id="bulk-order-dialog-title">
                Create Bulk Order
                <Button onClick={handleCloseBulkOrderModal} style={{ float: 'right' }}>
                  Cancel
                </Button>
              </DialogTitle>
              <DialogContent>
                <CreateBulkOrder />
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <StoreSelection stores={stores} onStoreSelect={handleStoreSelect} />
        )}

        {/* Install App Dialog */}
        <Dialog open={installDialogOpen} onClose={() => setInstallDialogOpen(false)}>
          <DialogTitle>Install App</DialogTitle>
          <DialogContent>
            <p>Would you like to install this app?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInstallClick} variant="contained" color="primary" startIcon={isMobile ? <InstallMobileIcon /> : <InstallDesktopIcon />}>
              Install
            </Button>
            <Button onClick={() => setInstallDialogOpen(false)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Router>
    </ThemeProvider>
  );
};

export default App;