import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const PickAndPack = () => {
  const [items, setItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [pickedItem, setPickedItem] = useState(null);
  const [pickedQty, setPickedQty] = useState(null);
  const [openQtyDialog, setOpenQtyDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const { user } = useAuth0();

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true);
      const accessToken = sessionStorage.getItem('wms_access_token');
      try {
        const response = await fetch('https://wms-api.neicha.com.au/all-items', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setItems(data);
        localStorage.setItem('items', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching items:', error);
        showSnackbar('Failed to fetch items', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();

    const handleBarcodeScan = (e) => {
      if (e.key === 'Enter' && barcode.length > 0) {
        processBarcode(barcode);
        setBarcode('');
      } else {
        setBarcode((prev) => prev + e.key);
      }
    };

    window.addEventListener('keypress', handleBarcodeScan);

    return () => {
      window.removeEventListener('keypress', handleBarcodeScan);
    };
  }, [barcode]);

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const processBarcode = async (scannedBarcode) => {
    if (!orderDetails) {
      // Fetch order details
      setLoading(true);
      const token = sessionStorage.getItem('pfresh_main_jwt_token');
      try {
        const response = await fetch(
          `https://www.petfresh.com.au/wp-json/wc/v3/get-order-pick/${scannedBarcode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch order details');
        }
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error('Error fetching order details:', error);
        showSnackbar(error.message || 'Error fetching order details', 'error');
        setOrderDetails(null);
      } finally {
        setLoading(false);
      }
    } else {
      // Match barcode to product
      const storedItems = JSON.parse(localStorage.getItem('items')) || [];
      const matchedItem = storedItems.find((item) => item.barcodes.includes(scannedBarcode));
      if (matchedItem) {
        setPickedItem(matchedItem);
        setOpenQtyDialog(true);
      }
    }
  };

  const handleConfirmPick = async () => {
    if (!pickedItem || pickedQty === null) return;
    setLoading(true);
    const token = sessionStorage.getItem('pfresh_main_jwt_token');
    try {
      const response = await fetch(
        `https://www.petfresh.com.au/wp-json/wc/v3/pick-order/${orderDetails.order_number}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sku: pickedItem.sku,
            qty: pickedQty,
            by: user.name,
          }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to mark item as picked');
      }

      // Update order display
      const updatedOrderDetails = { ...orderDetails };
      for (const category of Object.keys(updatedOrderDetails.items || {})) {
        for (const product of updatedOrderDetails.items[category]) {
          if (product.product_sku === pickedItem.sku) {
            product.picked_qty += pickedQty;
          }
        }
      }
      setOrderDetails(updatedOrderDetails);
    } catch (error) {
      console.error('Error marking as picked:', error);
      showSnackbar(error.message || 'Error marking item as picked', 'error');
    } finally {
      setLoading(false);
      setOpenQtyDialog(false);
      setPickedQty(null);
      setPickedItem(null);
    }
  };

  const renderOrderDetails = () => {
    if (!orderDetails) return null;
    const { order_number, driver_name, route_number, seq_number, autoship_customer, items } = orderDetails;
    return (
      <Card style={{ padding: '1em', marginTop: '5em' }}>
        <CardContent>
          <Typography variant="h6">Order Number: {order_number}</Typography>
          <Typography variant="body1">Driver: {driver_name}</Typography>
          <Typography variant="body1">Route: {route_number}</Typography>
          <Typography variant="body1">Sequence: {seq_number}</Typography>
          <Typography variant="body1">Autoship Customer: {autoship_customer ? 'Yes' : 'No'}</Typography>
        </CardContent>
      </Card>
    );
  };

  const renderItems = () => {
    if (!orderDetails) return null;
    const { items } = orderDetails;
    return Object.entries(items || {}).map(([category, products]) => (
      <div key={category}>
        <Typography variant="h6" style={{ marginTop: '1em' }}>{category}</Typography>
        {products.map((product) => (
          <Card
            key={product.item_id}
            style={{
              backgroundColor: product.picked_qty === product.product_qty
                ? 'green'
                : product.picked_qty > 0
                ? 'yellow'
                : 'red',
              marginTop: '0.5em',
              color: 'white',
            }}
          >
            <CardContent>
              <Typography variant="h6">{product.product_name}</Typography>
              <Typography>Required: {product.product_qty}</Typography>
              <Typography>Picked: {product.picked_qty}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    ));
  };

  const renderNumberPad = () => {
    const handleNumberClick = (number) => {
      setPickedQty((prev) => (prev === null ? number : parseInt(`${prev}${number}`, 10)));
    };

    const handleClear = () => {
      setPickedQty(null);
    };

    return (
      <Grid container spacing={2} style={{ marginTop: '16px', height: '100%' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
          <Grid item xs={4} key={number}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleNumberClick(number)}
              style={{ padding: '20px', fontSize: '1.5rem', height: '100%' }}
            >
              {number}
            </Button>
          </Grid>
        ))}
        <Grid item xs={8}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClear}
            style={{ padding: '20px', fontSize: '1.5rem', height: '100%' }}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" style={{ padding: '16px' }}>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {orderDetails ? (
        <>
          {renderOrderDetails()}
          {renderItems()}
        </>
      ) : (
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <GppMaybeIcon style={{ fontSize: '4rem', color: 'gray' }} />
          <Typography variant="h6">Scan Order</Typography>
          <Typography variant="body1">
            Please scan an order barcode to Pick!
          </Typography>
        </Grid>
      )}

      <Dialog open={openQtyDialog} onClose={() => setOpenQtyDialog(false)} fullWidth maxWidth="xs">
        <DialogContent>
          <Typography variant="h6" align="center">
            {pickedItem?.productname}
          </Typography>
          <Typography variant="h5" align="center">
            {pickedQty !== null ? pickedQty : ''}
          </Typography>
          {renderNumberPad()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmPick}
            color="primary"
            variant="contained"
            fullWidth
            style={{ padding: '16px', fontSize: '1.25rem', backgroundColor: pickedQty === null ? 'red' : '#9ba03b', color: 'white' }}
          >
            {pickedQty === null ? 'Cancel' : 'Mark as Picked'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default PickAndPack;